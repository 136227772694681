import ApiInstance from './ApiInstance.js'

export default {
  
  getProfession() {
    return ApiInstance({ requiresAuth: true }).get('/profession');
  },
  addProfession(Data) {
    return ApiInstance({ requiresAuth: true }).post('/profession', Data);
  },
  editProfession(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/profession/${id}`, Data);
  },
  deleteProfession(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/profession/${id}`);
  }
}
