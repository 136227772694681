<template>
  <div data-app>
    <div class="row mx-0">
      <div class="col-md-7 pl-0">
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue"
            >Job functions
            <span style="color: rgb(119, 119, 119); font-size: 11px"
              >(Job sector)</span
            ></span
          >
        </div>
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="name"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <!-- <td>{{ props.item.profession_name }}</td> -->
              <td>{{ props.item.job_function_name }}</td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeStatus(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <!-- <td style="display: flex; flex-direction: row">
                <v-btn
                  class="
                    btn btn-sm btn-success
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class="
                    btn btn-sm btn-danger
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  fab
                  small
                  @click="askUserAboutDelete(props.item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </td> -->
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item)"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 20px"></td>
            </tr>
          </template>

          <!-- <template v-slot:top>
            <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              fab
              small
              @click="createFormEnable"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
      </div>
      <div class="col-md-5 px-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue"
            >Add new Job Function
          </span>
        </div>
        <v-form
          ref="form"
          class="bg-white m-0 p-4"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="profession"
            :rules="nameRules"
            label="Profession"
            required
          ></v-text-field>
          <b-form-select
            v-model="jobFunctionId"
            :options="jobFunctionOptions"
            size="sm"
            class="mt-3"
          ></b-form-select>
          <div style="margin-top: 5%">
            <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
            >
              <!-- @click="createProfession"
              :disabled="
                profession === '' || profession === null ? true : false
              " -->
              Save
            </v-btn>
            <!-- <v-btn
              style="margin-left: 6%"
              class="
                btn btn-sm btn-danger
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="cancel"
            >
              Cancel
            </v-btn> -->
          </div>
        </v-form>
        <!-- create form end -->

        <!-- update form start -->
        <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="profession"
            :rules="nameRules"
            label="Profession"
            required
          ></v-text-field>
          <b-form-select
            v-model="jobFunctionId"
            :options="jobFunctionOptions"
            size="sm"
            class="mt-3"
          ></b-form-select>
          <div style="margin-top: 5%">
            <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="updateProfession"
              :disabled="
                profession === '' || profession === null ? true : false
              "
            >
              Update
            </v-btn>
            <v-btn
              style="margin-left: 6%"
              class="
                btn btn-sm btn-danger
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="cancel"
            >
              Cancel
            </v-btn>
          </div>
        </v-form>
        <!-- update form end -->
      </div>
    </div>
    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Do you really want to DELETE this item?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false"
              >No</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";
import ProfessionService from "@/MainServices/ProfessionService.js";

export default {
  data() {
    return {
      loading: true,
      dialog: false,
      deleteId: null,
      deleting: false,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      profession: "",
      nameRules: [
        (v) => !!v || "Profession name is required",
        (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
      ],
      headers: [
        // { text: "Profession Name", value: "profession_name" },
        { text: "Job Function", value: "job_function_name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data: [],
      jobFunctionId: null,
      jobFunctionOptions: [{ value: null, text: "Please select job function" }],
    };
  },
  mounted() {
    this.getInititalData();
    this.fetchJobFunctions();
  },
  methods: {
    getInititalData() {
      ProfessionService.getProfession()
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchJobFunctions() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-function`)
        .then((res) => {
          this.jobFunctions = res.data;
          res.data.forEach((element) => {
            this.jobFunctionOptions.push({
              value: element.id,
              text: element.job_function_name,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.dialog = false;
      this.createForm = false;
      this.updateForm = false;
      this.profession = "";
      this.jobFunctionId = null;
    },
    createFormEnable() {
      this.profession = "";
      this.jobFunctionId = null;
      this.createForm = true;
      this.updateForm = false;
    },

    createProfession() {
      if (this.profession == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      ProfessionService.addProfession({
        profession_name: this.profession,
        job_function_id: this.jobFunctionId,
      })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.profession = "";
          Swal.fire({
            title: "",
            text: "Profession added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateProfession() {
      ProfessionService.editProfession(
        {
          profession_name: this.profession,
          job_function_id: this.jobFunctionId,
        },
        this.profession_id
      )
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.profession = "";
          this.profession_id = "";
          Swal.fire({
            title: "",
            text: "Profession updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    changeStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/profession/${item.id}`, {
          profession_name: item.profession_name,
          job_function_id: item.job_function_id,
          status: !item.status,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.profession = "";
          this.profession_id = "";
          Swal.fire({
            title: "",
            text: "Profession updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.profession = item.profession_name;
      this.profession_id = item.id;
      this.jobFunctionId = item.job_function_id;
    },

    deleteButtonClick(item) {
      ProfessionService.deleteProfession(item)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Profession deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },

    deleteItem() {
      this.deleteButtonClick(this.deleteId);
    },
  },
};
</script>
